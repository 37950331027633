const mainPageConfig = {
    logoUrl: 'images/blockbase.png',
    logoSize: '200px',
    pageWidth: '90%',
    description: 'Join the best Minecraft server where creativity and community thrive! Explore custom worlds, epic builds, and more.',
    serverIPCopied: "Copied Server ID to clipboard!",
    server: {
        ip: 'play.hypixel.net',
        port: 25565,
    },
    titles: {
        welcomeTitle: 'Welcome to BlockBase',
        serverStatus: 'Server Status',
        playersOnline: 'Players Online',
        recentAnnouncements: 'Recent Announcements',
        serverIP: 'Server IP',
    },
    loadingText: 'Loading...',
    serverStatus: {
        online: 'Online',
        offline: 'Offline',
    },
    readMoreText: 'Read More',
    typography: {
        titleFontSize: '2rem',
        descriptionFontSize: '1rem',
        statusTitleFontSize: '1rem',
        statusValueFontSize: '1rem',
        announcementsTitleFontSize: '2rem',
        postTitleFontSize: '1.2rem',
        postDateFontSize: '1rem',
    },
    spacing: {
        top: '60px',
        bottom: '60px',
        betweenDescriptionAndButtons: '40px',
        announcementsTitleMarginTop: '60px',
    },
    buttons: [
        {
            label: 'Server Rules',
            link: '/rules',
            color: 'primary',
        },
        {
            label: 'Meet the Staff',
            link: '/staff',
            color: 'secondary',
        },
        {
            label: 'Vote for the Server',
            link: '/votes',
            color: 'primary',
        },
    ],
    posts: [
        {
            id: 1,
            title: 'Server Update 1.18!',
            content: 'We have updated to Minecraft version 1.18! Enjoy new terrain generation and more!',
            date: 'September 15, 2024',
            link: '/post/1',
            file: '/posts/tutorial.md',
            readMoreEnabled: true,
        },
        {
            id: 2,
            title: 'Event: Build Competition',
            content: 'Join us for our monthly build competition! Prizes include in-game currency and rare items.',
            date: 'September 20, 2024',
            link: '/post/2',
            file: '/posts/tutorial.md',
            readMoreEnabled: false,
        },
    ],
    altText: {
        logoAlt: 'Server Logo',
    },
    backgroundImageUrl: './assets/underwater.jpg', 
};

export default mainPageConfig;
