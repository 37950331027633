import React, { useEffect } from 'react';
import navbarConfig from '../config/navbarConfig';
import colorConfig from '../config/colorConfig'; // Import colorConfig for colors
import '../styles/footer.css';

const Footer = () => {
    useEffect(() => {
        const watermark = document.getElementById('blockbase-watermark');
        const watermarkLink = document.getElementById('blockbase-link');
        const originalWatermarkText = 'Created With BlockBase';
        const originalLink = 'https://docs.pogmc.net/blockbase';
        if (
            !watermark ||
            watermark.textContent !== originalWatermarkText ||
            !watermarkLink ||
            watermarkLink.href !== originalLink
        ) {
            document.body.innerHTML = '';
            document.body.style.backgroundColor = 'black';
            document.body.style.color = 'red';
            document.body.innerText = 'This site has been tampered with! DO NOT REMOVE BLOCKBASE CREDITS.';
        }
    }, []);

    return (
        <footer 
            className="footer"
            style={{
                backgroundColor: colorConfig.footer.backgroundColor,
                color: colorConfig.footer.textColor,
                borderTop: `1px solid ${colorConfig.footer.borderColor}`,
            }}
        >
            <p>&copy; {new Date().getFullYear()} {navbarConfig.serverName}</p>
            <p id="blockbase-watermark">
                Created With{' '}
                <a 
                    id="blockbase-link" 
                    href="https://docs.pogmc.net/blockbase" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ color: colorConfig.footer.linkColor }}
                >
                    BlockBase
                </a>
            </p>
        </footer>
    );
};

export default Footer;
