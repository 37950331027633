const rules = [
    {
        text: "No griefing",
        icon: "FaBan",
        fontSize: {
            icon: '1.5rem',
            text: '1.2rem',
        },
    },
    {
        text: "Be respectful to others",
        icon: "FaSmile",
        fontSize: {
            icon: '1.5rem',
            text: '1.2rem',
        },
    },
    {
        text: "No cheating or hacking",
        icon: "FaLock",
        fontSize: {
            icon: '1.5rem',
            text: '1.2rem',
        },
    },
    {
        text: "No IRL money trading for ingame items",
        icon: "FaMoneyBillAlt",
        fontSize: {
            icon: '1.5rem',
            text: '1.2rem',
        },
    },
];

export default rules;
