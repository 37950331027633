import { useEffect } from 'react';
import mainConfig from '../config/mainConfig';

const useUpdateMetaTags = () => {
    useEffect(() => {
        document.title = mainConfig.title;

        let favicon = document.querySelector("link[rel='icon']");
        if (favicon) {
            favicon.href = mainConfig.favicon;
        } else {
            favicon = document.createElement("link");
            favicon.rel = "icon";
            favicon.href = mainConfig.favicon;
            document.head.appendChild(favicon);
        }

        mainConfig.metaTags.forEach(meta => {
            const existingMeta = document.querySelector(`meta[name='${meta.name}']`) || document.querySelector(`meta[property='${meta.property}']`);
            if (existingMeta) {
                document.head.removeChild(existingMeta);
            }
        });

        mainConfig.metaTags.forEach(meta => {
            const metaTag = document.createElement('meta');
            if (meta.name) {
                metaTag.name = meta.name;
                metaTag.content = meta.content;
            } else if (meta.property) {
                metaTag.setAttribute('property', meta.property);
                metaTag.content = meta.content;
            }
            document.head.appendChild(metaTag);
        });
    }, []);
};

export default useUpdateMetaTags;
