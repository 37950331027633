import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import navbarConfig from '../config/navbarConfig';
import { FaBars, FaTimes } from 'react-icons/fa';
import colorConfig from '../config/colorConfig';
import '../styles/navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav
            className="navbar"
            style={{
                backgroundColor: colorConfig.navbar.backgroundColor,
                backdropFilter: colorConfig.backdropFilter,
                boxShadow: `0 4px 10px ${colorConfig.navbar.boxShadow}`,
            }}
        >
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/" style={{ color: colorConfig.navbar.logoColor }}>
                        {navbarConfig.serverName}
                    </Link>
                </div>

                <div
                    className="navbar-toggle"
                    onClick={toggleMenu}
                    style={{ color: colorConfig.navbar.iconColor }}
                >
                    {isOpen ? <FaTimes /> : <FaBars />}
                </div>

                <ul className="navbar-menu">
                    {navbarConfig.menuItems.map((item, index) => (
                        <li key={index}>
                            <Link to={item.path} className="navbar-link" style={{ color: colorConfig.navbar.linkColor }}>
                                {item.icon} {item.label} {/* Render Icon next to the label */}
                            </Link>
                        </li>
                    ))}
                    {navbarConfig.externalLinks.map((link, index) => (
                        <li key={index}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer" className="navbar-link" style={{ color: colorConfig.navbar.linkColor }}>
                                {link.icon} {link.label} {/* Render Icon next to the label */}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            {isOpen && (
                <div
                    className="navbar-responsive"
                    style={{
                        backgroundColor: colorConfig.navbar.backgroundColor,
                    }}
                >
                    <ul>
                        {navbarConfig.menuItems.map((item, index) => (
                            <li key={index}>
                                <Link to={item.path} onClick={toggleMenu} className="navbar-link" style={{ color: colorConfig.navbar.linkColor }}>
                                    {item.icon} {item.label}
                                </Link>
                            </li>
                        ))}
                        {navbarConfig.externalLinks.map((link, index) => (
                            <li key={index}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer" onClick={toggleMenu} className="navbar-link" style={{ color: colorConfig.navbar.linkColor }}>
                                    {link.icon} {link.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
