const voteLinks = [
    {
        title: 'Vote for the Server on Site 1',
        description: 'Support the server by voting on Site 1!',
        image: '/images/blockbase.png',
        link: 'https://votepage.com',
        imageWidth: '150px',
        imageHeight: '150px',
        titleFontSize: '1.5rem',
        descriptionFontSize: '1rem',
    },
    {
        title: 'Vote for the Server on Site 2',
        description: 'Support the server by voting on Site 2!',
        image: '/images/blockbase.png',
        link: 'https://votepage2.com',
        imageWidth: '150px',
        imageHeight: '150px',
        titleFontSize: '1.5rem',
        descriptionFontSize: '1rem',
    },
    {
        title: 'Vote for the Server on Site 3',
        description: 'Support the server by voting on Site 3!',
        image: '/images/blockbase.png',
        link: 'https://votepage3.com',
        imageWidth: '150px',
        imageHeight: '150px',
        titleFontSize: '1.5rem',
        descriptionFontSize: '1rem',
    },
];

export default voteLinks;
