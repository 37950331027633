const staffData = [
    {
        name: 'Admin 1',
        role: 'Server Admin',
        image: '/images/blockbase.png',
        imageSize: {
            width: '150px',
            height: '150px',
        },
        fontSize: {
            name: '1.5rem',
            role: '1rem',
        },
    },
    {
        name: 'Admin 2',
        role: 'Community Manager',
        image: '/images/blockbase.png',
        imageSize: {
            width: '200px',
            height: '200px',
        },
        fontSize: {
            name: '2rem',
            role: '1.2rem',
        },
    },
    {
        name: 'Admin 3',
        role: 'Server Admin',
        image: '/images/blockbase.png',
        imageSize: {
            width: '150px',
            height: '150px',
        },
        fontSize: {
            name: '1.5rem',
            role: '1rem',
        },
    },
];

export default staffData;
