import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Card, CardContent, Button, Grid, Snackbar } from '@mui/material';
import mainPageConfig from '../config/mainPageConfig';
import colorConfig from '../config/colorConfig';
import { Link } from 'react-router-dom';
import { FaServer, FaUsers } from 'react-icons/fa';
import useUpdateMetaTags from '../components/UseUpdateMetaTags';
import '../styles/mainPage.css';

const MainPage = () => {
    const [serverStatus, setServerStatus] = useState({ online: false, players: { online: 0, max: 0 } });
    const [isLoading, setIsLoading] = useState(true);
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        const fetchServerStatus = async () => {
            try {
                const response = await fetch(`https://api.mcsrvstat.us/3/${mainPageConfig.server.ip}:${mainPageConfig.server.port}`);
                const data = await response.json();
                setServerStatus(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching server status:', error);
                setIsLoading(false);
            }
        };

        fetchServerStatus();
    }, []);

    useUpdateMetaTags();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://pogmc.net/agendify/components.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const copyServerIP = () => {
        const serverIP = `${mainPageConfig.server.ip}:${mainPageConfig.server.port}`;
        navigator.clipboard.writeText(serverIP)
            .then(() => setNotification(true))
            .catch((err) => console.error('Failed to copy server IP: ', err));
    };

    const handleCloseNotification = () => {
        setNotification(false);
    };

    return (
        <div
            className="main-page-container"
            style={{
                width: mainPageConfig.pageWidth,
                backgroundColor: colorConfig.backgroundColor,
                paddingTop: mainPageConfig.spacing.top,
                paddingBottom: mainPageConfig.spacing.bottom,
                backgroundImage: `url(${mainPageConfig.backgroundImageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
            }}
        >
            <Box className="logo-container" mb={4}>
                <img
                    src={mainPageConfig.logoUrl}
                    alt={mainPageConfig.altText.logoAlt}
                    className="server-logo"
                    style={{
                        width: mainPageConfig.logoSize,
                        height: mainPageConfig.logoSize,
                    }}
                />
            </Box>

            <Typography
                align="center"
                sx={{
                    color: colorConfig.textColor,
                    fontSize: mainPageConfig.typography.titleFontSize,
                    fontWeight: 'bold',
                }}
            >
                {mainPageConfig.titles.welcomeTitle}
            </Typography>
            <Typography
                align="center"
                sx={{
                    color: colorConfig.textColor,
                    fontSize: mainPageConfig.typography.descriptionFontSize,
                    marginBottom: mainPageConfig.spacing.betweenDescriptionAndButtons,
                }}
            >
                {mainPageConfig.description}
            </Typography>

            <Box className="status-container" mb={6}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Card className="status-card glass-card" sx={{ backgroundColor: colorConfig.cardBackground }}>
                            <CardContent>
                                <Typography
                                    align="center"
                                    sx={{ color: colorConfig.textColor, fontSize: mainPageConfig.typography.statusTitleFontSize, fontWeight: 'bold' }}
                                >
                                    <FaServer /> {mainPageConfig.titles.serverStatus}
                                </Typography>
                                {isLoading ? (
                                    <Typography align="center" sx={{ color: colorConfig.textColor }}>
                                        {mainPageConfig.loadingText}
                                    </Typography>
                                ) : (
                                    <Typography
                                        align="center"
                                        sx={{ color: serverStatus.online ? colorConfig.onlineColor : colorConfig.offlineColor, fontSize: mainPageConfig.typography.statusValueFontSize }}
                                    >
                                        {serverStatus.online ? mainPageConfig.serverStatus.online : mainPageConfig.serverStatus.offline}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Card className="status-card glass-card" sx={{ backgroundColor: colorConfig.cardBackground }}>
                            <CardContent>
                                <Typography
                                    align="center"
                                    sx={{ color: colorConfig.textColor, fontSize: mainPageConfig.typography.statusTitleFontSize, fontWeight: 'bold' }}
                                >
                                    <FaUsers /> {mainPageConfig.titles.playersOnline}
                                </Typography>
                                {isLoading ? (
                                    <Typography align="center" sx={{ color: colorConfig.textColor }}>
                                        {mainPageConfig.loadingText}
                                    </Typography>
                                ) : (
                                    <Typography
                                        align="center"
                                        sx={{ color: colorConfig.playersOnlineColor, fontSize: mainPageConfig.typography.statusValueFontSize }}
                                    >
                                        {serverStatus.players.online}/{serverStatus.players.max}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3}>
                    <Card className="status-card glass-card" sx={{ backgroundColor: colorConfig.cardBackground }}>
                            <CardContent>
                                <Typography
                                    align="center"
                                    sx={{ color: colorConfig.textColor, fontSize: mainPageConfig.typography.statusTitleFontSize, fontWeight: 'bold' }}
                                >
                                    <FaUsers /> {mainPageConfig.titles.serverIP}
                                </Typography>
                                <Typography
                                    align="center"
                                    sx={{ color: colorConfig.textColor, fontSize: mainPageConfig.typography.statusValueFontSize, cursor: 'pointer' }}
                                    onClick={copyServerIP}
                                >
                                    {mainPageConfig.server.ip}:{mainPageConfig.server.port}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            
            <Box className="button-group">
                {mainPageConfig.buttons.map((button, index) => (
                    <Button
                        key={index}
                        variant="contained"
                        href={button.link}
                        className={`button-${button.color}`}
                        sx={{
                            backgroundColor: colorConfig[`${button.color}Color`],
                            color: colorConfig.buttonTextColor,
                        }}
                    >
                        {button.label}
                    </Button>
                ))}
            </Box>

            <Typography
                className="announcements-title"
                sx={{ color: colorConfig.textColor, fontSize: mainPageConfig.typography.announcementsTitleFontSize, marginTop: mainPageConfig.spacing.announcementsTitleMarginTop }}
            >
                {mainPageConfig.titles.recentAnnouncements}
            </Typography>
            <Box className="announcements-grid">
                {mainPageConfig.posts.map((post, index) => (
                    <Card key={index} className="announcement-card glass-card %%__NONCE__%%" sx={{ backgroundColor: colorConfig.cardBackground }}>
                        <CardContent>
                            <Typography className="post-title" sx={{ color: colorConfig.textColor, fontSize: mainPageConfig.typography.postTitleFontSize }}>
                                {post.title}
                            </Typography>
                            <Typography className="post-date" sx={{ color: colorConfig.postDateColor, fontSize: mainPageConfig.typography.postDateFontSize }}>
                                {post.date}
                            </Typography>
                            <Typography sx={{ color: colorConfig.textColor }}>
                                {post.content}
                            </Typography>
                            {post.readMoreEnabled && (
                                <Button
                                    variant="outlined"
                                    className="read-more-button"
                                    sx={{ color: colorConfig.primaryColor, borderColor: colorConfig.primaryColor }}
                                    component={Link}
                                    to={post.link}
                                >
                                    {mainPageConfig.readMoreText}
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                ))}
            </Box>

            <Snackbar
                open={notification}
                autoHideDuration={3000}
                onClose={handleCloseNotification}
                message={mainPageConfig.serverIPCopied}
            />
        </div>
    );
};

export default MainPage;
