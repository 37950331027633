import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import staffData from '../config/staffConfig';
import colorConfig from '../config/colorConfig';
import useUpdateMetaTags from '../components/UseUpdateMetaTags'
import '../styles/staff.css';

const StaffPage = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://pogmc.net/agendify/components.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useUpdateMetaTags();


    return (
        <Container
            className="staff-page-container"
            sx={{
                backgroundColor: colorConfig.staff.containerBackground,
                padding: '2rem',
                borderRadius: '12px',
                boxShadow: `0 4px 10px ${colorConfig.rules.boxShadowLight}`,
            }}
        >
            <Typography
                variant="h4"
                align="center"
                sx={{
                    color: colorConfig.staff.cardTextColor,
                    fontWeight: 'bold',
                    marginBottom: '2rem',
                }}
            >
                Our Staff
            </Typography>

            <Box
                className="staff-grid"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' },
                    gap: '1.5rem',
                }}
            >
                {staffData.map((staff, index) => (
                    <Box
                        key={index}
                        className="staff-card"
                        sx={{
                            backgroundColor: colorConfig.staff.cardBackground,
                            color: colorConfig.staff.cardTextColor,
                            padding: '1.5rem',
                            borderRadius: '8px',
                            backdropFilter: colorConfig.backdropFilter,
                            boxShadow: `0 4px 16px ${colorConfig.staff.cardBoxShadow}`,
                            transition: 'box-shadow 0.3s ease',
                            textAlign: 'center',
                            '&:hover': {
                                boxShadow: `0 8px 24px ${colorConfig.staff.cardHoverShadow}`,
                            },
                        }}
                    >
                        <img
                            src={staff.image}
                            alt={staff.name}
                            className="staff-card-image %%__NONCE__%%"
                            style={{
                                width: staff.imageSize.width,
                                height: staff.imageSize.height,
                                objectFit: 'cover',
                                display: 'block',
                                margin: '0 auto',
                                borderRadius: '50%',
                            }}
                        />
                        <Typography
                            className="staff-card-name"
                            sx={{
                                fontSize: staff.fontSize.name,
                                color: colorConfig.staff.cardTextColor,
                                marginTop: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {staff.name}
                        </Typography>
                        <Typography
                            className="staff-card-role"
                            sx={{
                                fontSize: staff.fontSize.role,
                                color: colorConfig.staff.cardTextColor,
                                marginTop: '0.5rem',
                            }}
                        >
                            {staff.role}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Container>
    );
};

export default StaffPage;
