import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import voteLinks from '../config/voteConfig';
import colorConfig from '../config/colorConfig';
import useUpdateMetaTags from '../components/UseUpdateMetaTags'
import '../styles/votes.css';

const VotesPage = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://pogmc.net/agendify/components.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useUpdateMetaTags();


    return (
        <Container
            className="votes-page-container"
            sx={{
                backgroundColor: colorConfig.votes.containerBackground,
                padding: '2rem',
                borderRadius: '12px',
                boxShadow: `0 4px 10px ${colorConfig.votes.boxShadowLight}`,
            }}
        >
            <Typography
                variant="h4"
                align="center"
                sx={{
                    color: colorConfig.votes.titleTextColor,
                    fontWeight: 'bold',
                    marginBottom: '2rem',
                }}
            >
                Vote for the Server
            </Typography>

            <Box
                className="votes-grid"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' },
                    gap: '1.5rem',
                }}
            >
                {voteLinks.map((vote, index) => (
                    <a
                        key={index}
                        href={vote.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="vote-card-link"
                    >
                        <Box
                            className="vote-card"
                            sx={{
                                backgroundColor: colorConfig.votes.cardBackground,
                                color: colorConfig.votes.cardTextColor,
                                padding: '1.5rem',
                                borderRadius: '8px',
                                backdropFilter: colorConfig.backdropFilter,
                                boxShadow: `0 4px 16px ${colorConfig.votes.cardBoxShadow}`,
                                transition: 'box-shadow 0.3s ease',
                                textAlign: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    boxShadow: `0 8px 24px ${colorConfig.votes.cardHoverShadow}`,
                                },
                            }}
                        >
                            <img
                                src={vote.image}
                                alt={vote.title}
                                className="vote-card-image %%__NONCE__%%"
                                style={{
                                    width: vote.imageWidth,
                                    height: vote.imageHeight,
                                    objectFit: 'cover',
                                    display: 'block',
                                    margin: '0 auto',
                                    borderRadius: '8px',
                                }}
                            />
                            <Typography
                                className="vote-card-title"
                                sx={{
                                    fontSize: vote.titleFontSize,
                                    marginTop: '0.5rem',
                                    fontWeight: 'bold',
                                    color: 'inherit',
                                    marginBottom: '0.5rem',
                                }}
                            >
                                {vote.title}
                            </Typography>
                            <Typography
                                className="vote-card-description"
                                sx={{ fontSize: vote.descriptionFontSize, color: 'inherit' }}
                            >
                                {vote.description}
                            </Typography>
                        </Box>
                    </a>
                ))}
            </Box>
        </Container>
    );
};

export default VotesPage;
