const colorConfig = {
    primaryColor: '#90caf9',
    secondaryColor: '#f48fb1',
    backgroundColor: 'rgba(18, 18, 18, 0.6)',
    textColor: '#ffffff',
    buttonTextColor: '#ffffff',
    onlineColor: '#ffffff',
    playersOnlineColor: '#ffffff',
    postDateColor: '#838383',
    cardBackground: 'rgba(255, 255, 255, 0.1)',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
    rules: {
        containerBackground: 'rgba(18, 18, 18, 0.6)',
        cardBackground: 'rgba(255, 255, 255, 0.1)',
        boxShadowLight: 'rgba(0, 0, 0, 0.1)',
        boxShadowMedium: 'rgba(0, 0, 0, 0.2)',
        boxShadowHover: 'rgba(0, 0, 0, 0.3)',
        textColor: '#ffffff',
    },
    staff: {
        cardBackground: 'rgba(255, 255, 255, 0.1)',
        cardBoxShadow: 'rgba(0, 0, 0, 0.2)',
        cardHoverShadow: 'rgba(0, 0, 0, 0.3)',
        cardTextColor: '#ffffff',
        containerBackground: 'rgba(18, 18, 18, 0.6)',
    },
    votes: {
        containerBackground: 'rgba(18, 18, 18, 0.6)',
        cardBackground: 'rgba(255, 255, 255, 0.1)',
        cardBoxShadow: 'rgba(0, 0, 0, 0.2)',
        cardHoverShadow: 'rgba(0, 0, 0, 0.3)',
        boxShadowLight: 'rgba(0, 0, 0, 0.1)',
        titleTextColor: '#ffffff',
        cardTextColor: '#ffffff',
    },
    posts: {
        containerBackground: 'rgba(18, 18, 18, 0.7)',
        boxShadowLight: 'rgba(0, 0, 0, 0.1)',
        borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    navbar: {
        backgroundColor: 'rgba(31, 41, 55, 0.9)',
        boxShadow: 'rgba(0, 0, 0, 0.1)',
        logoColor: '#ffffff',
        linkColor: 'rgba(209, 213, 219, 0.9)',
        iconColor: '#ffffff',
    },
    footer: {
        backgroundColor: 'rgba(18, 18, 18, 0.7)',
        textColor: '#ffffff',
        linkColor: '#90caf9',
        borderColor: 'rgba(255, 255, 255, 0.1)',
    },
};

export default colorConfig;
