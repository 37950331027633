import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import RulesPage from './pages/RulesPage';
import StaffPage from './pages/StaffPage';
import VotesPage from './pages/VotesPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PostPage from './pages/PostPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './styles/App.css';

function App() {
    const checkFooterTamper = () => {
        const watermark = document.getElementById('blockbase-watermark');
        const watermarkLink = document.getElementById('blockbase-link');
        const originalWatermarkText = 'Created With BlockBase';
        const originalLink = 'https://docs.pogmc.net/blockbase';
        if (
            !watermark ||
            watermark.textContent !== originalWatermarkText ||
            !watermarkLink ||
            watermarkLink.href !== originalLink
        ) {
            document.body.innerHTML = '';
            document.body.style.backgroundColor = 'black';
            document.body.style.color = 'red';
            document.body.innerText = 'This site has been tampered with! DO NOT REMOVE BLOCKBASE CREDITS.';
        }
    };

    useEffect(() => {
        checkFooterTamper();
    }, []);

    return (
            <Router>
                <div className="app-container">
                    <Navbar />
                    <div className="main-content">
                        <Routes>
                            <Route path="/" element={<MainPage />} />
                            <Route path="/rules" element={<RulesPage />} />
                            <Route path="/staff" element={<StaffPage />} />
                            <Route path="/votes" element={<VotesPage />} />
                            <Route path="/post/:postId" element={<PostPage />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
    );
}

export default App;
