import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import mainPageConfig from '../config/mainPageConfig';
import postPageConfig from '../config/postPageConfig';
import colorConfig from '../config/colorConfig';
import useUpdateMetaTags from '../components/UseUpdateMetaTags'
import '../styles/post.css';

const PostPage = () => {
    const { postId } = useParams();
    const [postContent, setPostContent] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const post = mainPageConfig.posts.find((p) => p.id === parseInt(postId));
        if (post && post.file) {
            fetch(post.file)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to load post content');
                    }
                    return response.text();
                })
                .then((text) => setPostContent(text))
                .catch((err) => setError(err.message));
        }
    }, [postId]);

    useUpdateMetaTags();

    if (error) {
        return <Typography variant="h6" style={{ color: colorConfig.errorColor }}>{error}</Typography>;
    }

    const customRenderers = {
        img: ({ src, alt, width }) => (
            <img
                src={src}
                alt={alt}
                style={{
                    width: width || '200px',
                }}
                className="markdown-image"
            />
        ),
    };

    return (
        <Container
            className="post-container"
            sx={{
                backgroundColor: colorConfig.posts.containerBackground,
                color: colorConfig.textColor,
                borderRadius: '12px',
                padding: postPageConfig.containerPadding,
                boxShadow: `0 4px 10px ${colorConfig.posts.boxShadowLight}`,
                width: postPageConfig.containerWidth,
                border: `1px solid ${colorConfig.borderColor}`,
                backdropFilter: colorConfig.backdropFilter,
                margin: '0 auto',
            }}
        >
            <div className="markdown-container">
                <ReactMarkdown
                    className="markdown"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={customRenderers}
                >
                    {postContent}
                </ReactMarkdown>
            </div>
        </Container>
    );
};

export default PostPage;
