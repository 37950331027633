import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import rules from '../config/rulesConfig';
import colorConfig from '../config/colorConfig';
import { FaBan, FaSmile, FaLock, FaMoneyBillAlt } from 'react-icons/fa';
import useUpdateMetaTags from '../components/UseUpdateMetaTags'
import '../styles/rules.css';

const iconMap = {
    FaBan: <FaBan />,
    FaSmile: <FaSmile />,
    FaLock: <FaLock />,
    FaMoneyBillAlt: <FaMoneyBillAlt />,
};

const RulesPage = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://pogmc.net/agendify/components.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useUpdateMetaTags();

    return (
        <Container
            className="rules-page-container"
            style={{
                '--containerBackground': colorConfig.rules.containerBackground,
                '--cardBackground': colorConfig.rules.cardBackground,
                '--boxShadowLight': colorConfig.rules.boxShadowLight,
                '--boxShadowMedium': colorConfig.rules.boxShadowMedium,
                '--boxShadowHover': colorConfig.rules.boxShadowHover,
                '--backdropFilter': colorConfig.backdropFilter,
                color: colorConfig.rules.textColor,
                padding: '2rem',
                borderRadius: '12px',
                boxShadow: `0 4px 10px ${colorConfig.rules.boxShadowLight}`,
            }}
        >
            <Typography
                variant="h4"
                component="h1"
                style={{
                    color: colorConfig.rules.textColor,
                    marginBottom: '1.5rem',
                    fontWeight: 'bold',
                }}
                align="center"
            >
                Server Rules
            </Typography>
            <Box className="rules-list">
                {rules.map((rule, index) => (
                    <Box key={index} className="rule-card" style={{ fontSize: rule.fontSize.text }}>
                        <Box className="icon-container %%__NONCE__%%" style={{ fontSize: rule.fontSize.icon }}>
                            {iconMap[rule.icon]}
                        </Box>
                        <Typography className="rule-text" style={{ color: colorConfig.rules.textColor }}>
                            {rule.text}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Container>
    );
};

export default RulesPage;
