import { FaHome, FaBook, FaUsers, FaVoteYea, FaDiscord, FaStoreAlt } from 'react-icons/fa';

const navbarConfig = {
    serverName: 'BlockBase',
    menuItems: [
        {
            label: 'Home',
            path: '/',
            icon: <FaHome />,
        },
        {
            label: 'Rules',
            path: '/rules',
            icon: <FaBook />,
        },
        {
            label: 'Staff',
            path: '/staff',
            icon: <FaUsers />,
        },
        {
            label: 'Votes',
            path: '/votes',
            icon: <FaVoteYea />,
        },
    ],
    externalLinks: [
        {
            label: 'Discord',
            url: 'https://discord.com',
            icon: <FaDiscord />,
        },
        {
            label: 'Store',
            url: 'https://store.example.com',
            icon: <FaStoreAlt />,
        },
    ],
};

export default navbarConfig;
