const mainConfig = {
    title: "BlockBase",
    description: "An awesome Minecraft Website available for purchase on BuiltByBit!",
    favicon: "favicon.ico",
    metaTags: [
        { name: "description", content: "An awesome Minecraft Website available for purchase on BuiltByBit!" },
        { name: "keywords", content: "BuiltByBit, BlockBase, AbisGamer, Website" },
        { property: "og:title", content: "BlockBase" },
        { property: "og:description", content: "An awesome Minecraft Website available for purchase on BuiltByBit!" },
        { property: "og:type", content: "website" },
        { property: "og:image", content: "/images/blockbase.png" },
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:title", content: "BlockBase" },
        { property: "twitter:description", content: "An awesome Minecraft Website available for purchase on BuiltByBit!" },
        { property: "twitter:image", content: "/images/blockbase.png" },
    ],
};

export default mainConfig;
